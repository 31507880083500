import React from "react"
import Layout from "../components/layout"
import RichText from "../components/richtext"
import SEO from "../components/seo"

function PageTemplate({ pageContext }) {
  const { page } = pageContext
  return (
    <Layout>
      <SEO title={page.title}/>
      <article className="max-w-3xl mx-auto md:pb-24 md:pt-8 px-4 md:px-0">
        <RichText content={page.body.raw}/>
      </article>
    </Layout>
  )
}

export default PageTemplate